@use "partials/variables";

html,
body,
#root {
  height: 100vh;
  margin: 0;
  background-image: linear-gradient($background_color, #150649);
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color;
  text-align: left; // 3
}

#tsparticles {
  height: 100%;
}
